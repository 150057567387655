<template>
    <div>
        <el-dialog
            title="订单退款"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="140px">
                <p class="a-c-blue">券包金额￥{{ (orderPrice / 100).toFixed(2) }}，共{{ couponNum || 0 }}张优惠券，已使用{{ useNum || 0 }}张，已过期{{ couponExpiredNum || 0 }}张，待使用{{ couponNeedUseNum || 0 }}张</p>
                <el-form-item label="退款金额" prop="refundAmount">
                    <jd-input-price v-model="form.refundAmount"></jd-input-price>
                </el-form-item>
                <el-form-item label="退还未使用优惠券" prop="returnGoods">
                    <el-radio-group v-model="form.returnGoods">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="退款原因" prop="refundMsg">
                    <el-input
                        type="textarea"
                        :rows="4"
                        maxlength="80"
                        placeholder="请输入内容"
                        v-model="form.refundMsg">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    refundAmount: '',
                    refundDiscountVoList: [], //优惠信息
                    refundMsg: '',
                    returnGoods: 0
                },
                id: '',
                orderPrice: 0,
                useNum: 0,
                couponNum: 0,
                couponNeedUseNum: 0,
                couponExpiredNum: 0,
                rules:{
                    refundAmount: [{required: true, message:'请输入退款金额', trigger: 'blur'}],
                    refundMsg: [{required: true, message:'请输入退款原因', trigger: 'blur'}]
                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let reqForm = {
                            ...this.form,
                            refundAmount: Number((this.form.refundAmount*100).toFixed(0)),
                        }
                        this.orderRefound(reqForm)
                    }
                })
            },
            orderRefound (reqForm) {
                this.$Axios._post({
                    url: this.$Config.apiUrl.superOrderRefund,
                    method: "post",
                    params: {
                        ...reqForm,
                        id:this.id,
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        this.dialogVisible = false
                        this.$emit('oprateFinish')
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                    
                })
            },
        }
    }
</script>