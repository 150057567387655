<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">优惠券订单</span>
            <!-- <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div> -->
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="订单编号" v-model="pageParam.params.orderSn" />
                <le-input label="关联活动ID" v-model="pageParam.params.activityId" />
                <le-input label="优惠券ID" v-model="pageParam.params.couponId" />
                <le-input label="用户手机号" v-model="pageParam.params.mobile" />
                <le-input label="充电订单" v-model="pageParam.params.chargeOrderId" />
                <le-select-local-search label="支付状态" v-model="pageParam.params.payStatus" :options="payStatusDic" />
                <le-select-local-search label="支付渠道" v-model="pageParam.params.payChannel" :options="payChannelDic" />
            </le-search-form>
            <le-pagview ref="couponOrder" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.couponOrderList">
                <el-table 
                    ref="couponOrderList"
                    :data="tableData" 
                    :highlight-current-row="true" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }"
                    style="width: 100%">
                    <el-table-column prop="deviceCode" fixed="left" label="订单编号" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderSn?row.orderSn:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="关联活动"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="handleToActivity(row)">{{ row.activityName?row.activityName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="关联活动ID"
                        min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.activityId?row.activityId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="关联优惠券"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="handleToCoupon(row.goodsInfos[0])">{{ row.goodsInfos?row.goodsInfos[0].couponName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="关联优惠券ID"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.goodsInfos?row.goodsInfos[0].couponId:'-' }}</span>
                        </template>
                    </el-table-column>    
                    <!-- <el-table-column 
                        prop="deviceCode" 
                        label="可用站点"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.deviceCode?row.deviceCode:'-' }}-{{ row.boxId }}</span>
                        </template>
                    </el-table-column>     -->
                    <el-table-column 
                        prop="name" 
                        label="用户手机号"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile || '-' }}</span>
                        </template>
                    </el-table-column>                 
                    <el-table-column 
                        prop="name" 
                        label="支付渠道"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChannel | initDic(payChannelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="订单金额"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="实际支付"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.payAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        min-width="120"
                        label="支付状态" >
                        <template slot-scope="{ row }">
                            <span>{{ row.payStatus | initDic(payStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column 
                        prop="name" 
                        min-width="120"
                        label="退款方式" >
                        <template slot-scope="{ row }">
                            <span>{{ row.payStatus | initDic(payStatusDic) }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="退款" min-width="120">
						<template slot-scope="{ row }">
							<div class="a-flex-cfsfs" style="align-items: flex-start">
                                <span>现金：￥{{ util.numFormat(row.refundAmount) }}</span>
							    <span v-for="(item,index) in row.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.refundPayAmount) }}
                                </span>
                            </div>
						</template>
					</el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="下单时间"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime?row.createTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="关联充电订单"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="toChargeOrderInfo(row)">{{ row.chargeOrderId?row.chargeOrderId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="90" fixed="right" >
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="订单详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toMoreInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="退款" placement="top" v-if="userInfo.dataPermission == 99 || company.companyType == 2">
                                <img src="../../assets/icon/option-refund.png" class="a-wh-16" 
                                    v-if="scope.row.payStatus == 1 || scope.row.payStatus == 2 || scope.row.payStatus == 4" 
                                    @click="refound(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <coupon-order-refound ref="couponOrderRefound"></coupon-order-refound>
    </div>
</template>

<script>
import util from '../../utils/util'
import { mapState } from 'vuex';
import CouponOrderRefound from './child/couponOrderRefound.vue';
let _this
export default {
    components:{
        CouponOrderRefound
    },
    data() {
        return {
            util: util,
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.couponOrderPage,
                method: "post",
                params: {
                    activityId: '',
                    chargeOrderId: '',
                    couponId: '',
                    mobile: "",
                    orderSn: "",
                    payChannel: '',
                    payStatus: ''
                },
                freshCtrl: 1,
            },
            payChannelDic: [],//支付渠道字典
            orderStatusDic: [],//订单状态字典
            payStatusDic: [],//支付状态字典
            goodsTypeDic: [],//商品订单类型字典
        };
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    created () {
        this.$getDic('payChannel','select').then(res=>{ this.payChannelDic = res; })
        this.$getDic('boxOrderStatus').then(res=>{ this.orderStatusDic = res; })
        this.$getDic('payStatus','select').then(res=>{ this.payStatusDic = res; })
        this.$getDic('goodsType').then(res=>{ this.goodsTypeDic = res; })
    },
    activated () {
		this.pageParam.freshCtrl++;
	},
    methods: {
        setTableData(data) {
            // this.tableData = data;
            this.tableData = data.map(item=>{
                let refoundAmountAllPoint = 0
                let payAmountAllPoint = 0
                if(item.orderDiscountInfoList && item.orderDiscountInfoList.length) {
                    item.orderDiscountInfoList.map(ite=>{
                        if(ite.discountType == 4) {
                            refoundAmountAllPoint += Number(ite.refundPayAmount)
                            payAmountAllPoint += Number(ite.discountPayAmount)
                        }
                    })
                }
                
                return {
                    ...item,
                    refoundAmountAllPoint: refoundAmountAllPoint,
                    payAmountAllPoint: payAmountAllPoint,
                }
            });
        },
        handlerRest() {
            this.pageParam.params = {
                activityId: '',
                chargeOrderId: '',
                couponId: '',
                mobile: "",
                orderSn: "",
            };
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['couponOrder'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        // 订单退款
        async refound (datas) {
            let orderInfo = await this.getOrderInfo(datas.id)
            this.$refs['couponOrderRefound'].id = orderInfo.id
            this.$refs['couponOrderRefound'].orderPrice = orderInfo.payAmount
            this.$refs['couponOrderRefound'].useNum = orderInfo.couponUsedNum
            this.$refs['couponOrderRefound'].couponNum = orderInfo.couponNum
            this.$refs['couponOrderRefound'].couponExpiredNum = orderInfo.couponExpiredNum
            this.$refs['couponOrderRefound'].couponNeedUseNum = orderInfo.couponNeedUseNum
            this.$refs['couponOrderRefound'].form.refundAmount = Number(((orderInfo.payAmount - orderInfo.refundAmount)/100).toFixed(2))
            this.$refs['couponOrderRefound'].form.returnGoods = 1
            this.$refs['couponOrderRefound'].dialogVisible = true
        },
        getOrderInfo (id) {
            return new Promise((resolve, reject)=>{
                this.$Axios._post({
                    url: this.$Config.apiUrl.couponOrderDetail,
                    params: {
                        id: id
                    }
                }).then(({data}) => {
                    resolve(data)
                }).catch(err=>{
                    reject()
                })
            })
            
        },
        // 订单信息
        toMoreInfo (datas) {
            this.$router.push({
                path:'/order/coupon-order-info',
                query: {
                    orderId: datas.id,
                    orderSn: datas.orderSn
                }
            })
        },
        handleToActivity (datas) {
            if(datas.activityId) {
                this.$router.push({
                    path: '/activity/activity-edit',
                    query:{
                        id: datas&&datas.activityId?datas.activityId:''
                    }
                })
            }
            
        },
        handleToCoupon (datas) {
            this.$router.push({
                path: '/activity/coupon-edit',
                query:{
                    id: datas&&datas.couponId?datas.couponId:''
                }
            })
        },
        toChargeOrderInfo (datas) {
            if(datas.relationOrderType == 4 || datas.relationOrderType == 5) {
                this.$router.push({
                    path:'/order/chargeCabinet-order-info',
                    query: {
                        orderId: datas.chargeOrderId,
                        orderSn: datas.chargeOrderId
                    }
                })
            }else if(datas.relationOrderType == 2) {
                this.$router.push({
                    path: '/order/order-detail',
                    query: {
                        orderId: datas.chargeOrderId,
                    }
                })
            }
        },
        exportfile () {
            if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime).length > 92){
                this.$message.error('最大导出范围三个月')
                return
            }
            let startDate = this.pageParam.params.startTime || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
            let endDate = this.pageParam.params.endTime || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
            this.$exfile({
                code: 14,
                fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 充电柜订单',
                startTime: startDate.split(' ')[0],
                endTime: endDate.split(' ')[0],
                params: {
                    ...this.pageParam.params,
                    startDate: startDate,
                    endDate: endDate
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
    width: 75px !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>
